import React from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Heading } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";

export default function PlansPage() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>QUICKALL</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      
      <div className="flex w-full flex-col items-center bg-white-A700">
        <Header className="self-stretch"/>

        {/* Main Section */}
        <div className="pt-[100px] sm:pt-15 md:pt-28 container-md mt-4 flex justify-center p-5 md:p-10">
          <div className="flex w-full flex-col items-center gap-10">

            {/* Page Title */}
            <div className="flex w-full flex-col items-center gap-4 md:w-full text-center">
              <Heading as="h1" className="text-black-900 text-3xl sm:text-2xl md:text-2xl">
                Barter Certificate Plans
              </Heading>
              <Text as="p" className="font-normal leading-7">
                Quickall's Barter certificate plans are designed for everyone. 
                Select a suitable plan to become a Money Manager and participate in secure, 
                efficient trading.
              </Text>
            </div>

            {/* Plan Cards Section */}
            <div className="flex flex-wrap gap-10 justify-center items-center w-full z-[47]">
              
              {/* Standard Plan */}
              <PlanCard
                title="Standard"
                priceRange="$500 - $10000"
                description="You will get equivalent value of GGQ Coins after 6 months."
                onClick={() => navigate("/bartercertificatesilver")}
                bgGradient="bg-gradient5"
                textColor="#ffffff"
              />
              
              {/* Premium Plan */}
              <PlanCard
                title="Premium"
                priceRange="$10001 - $20000"
                description="You will get equivalent value of GGQ Coins after 6 months and a bonus of 5%."
                onClick={() => navigate("/bartercertificategold")}
                bgGradient="bg-gradient2"
                textColor="#000000"
              />

              {/* Pro Plan */}
              <PlanCard
                title="Pro"
                priceRange="$20001 and above"
                description="You will get equivalent value of GGQ Coins after 6 months and a bonus of 10%."
                onClick={() => navigate("/bartercertificateplatinium")}
                bgGradient="bg-gradient4"
                textColor="#ffffff"
              />
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
}

function PlanCard({ title, priceRange, description, onClick, bgGradient, textColor}) {
  return (
    <div className={`relative flex flex-col items-center w-[30%] md:w-full rounded-lg ${bgGradient} border-[3px] border-yellow-500 shadow-lg pb-12 pr-14 pl-14 pt-4 md:pb-5 md:pl-5 sm:min-h-[400px] min-h-[300px]`}>
      
      <Text as="h4" className=" text-4xl text-shadow-ts text-center" style={{ color: textColor }}>
        {title}
      </Text>
      <Heading as="h6" className="mt-6 text-[18px] text-center sm:text-xl" style={{ color: textColor }}>
        {priceRange}
      </Heading>
      <Text as="p" className="mt-4 w-full font-normal leading-7 text-left" style={{ color: textColor }}>
        {description}
      </Text>
      <Button
        color="teal_900"
        onClick={onClick}
        className="absolute left-1/2 transform -translate-x-1/2 bottom-1 w-[50%] rounded-full border-[3px] border-yellow-500 font-medium mt-6"
      >
        Get started
      </Button>
    </div>
  );
}
