import React from "react";
import { Helmet } from "react-helmet";
import { Img, Text, Heading } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Link } from "react-router-dom";

export default function BlogPagePage() {
  return (
    <>
      <Helmet>
        <title>QUICKALL</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full flex-col items-center gap-12 bg-white-A700 md:gap-6 sm:gap-3">
        <Header className="self-stretch" />
        
        <div className="pt-[120px] md:pt-28 mx-auto flex w-full max-w-[1281px] flex-col gap-[60px] md:gap-[45px] md:p-5 sm:gap-[30px]">
          <div className="flex flex-col items-center">
            <div className="flex w-[74%] flex-col gap-[17px] md:w-full">
            <Link
                      to="/readblog6"
                      style={{ color: "ffffff", textDecoration: "none" }}
                      className="w-full leading-9"
                      >
                <Heading as="h1" className="text-center !text-black-900 md:leading-[50px] leading-[65px] sm:leading-[40px] sm:text-3xl">
                The Role of AI in Preventing Centralization in Blockchain Systems
                </Heading>
              </Link>
              <div className="ml-[275px] flex w-[46%] flex-wrap justify-between gap-5 md:ml-0 md:w-full">
                <Text as="p" className="self-start !text-blue_gray-900_dd">
                  G Chin
                </Text>
                <Text as="p" className="self-end !text-blue_gray-900_01">
                  Posted on August 23, 2024
                </Text>
              </div>
            </div>
            <Img
              src="images/blog7_banner.webp"
              alt="httpswwwpex"
              className="mt-8 h-[477px] w-full object-cover md:h-auto"
            />
            <Text as="p" className="mt-12 w-[65%] text-center !font-normal leading-7 md:w-full">
            Blockchain technology is widely regarded for its decentralized nature, offering a 
            secure and transparent alternative to traditional centralized systems. However, as 
            blockchain networks evolve, challenges such as centralization arise, where control and 
            influence may concentrate among a few entities, undermining the core principles of 
            decentralization.
            </Text>
            <Link
                      to="/readblog6"
                      style={{ color: "ffffff", textDecoration: "none" }}
                      className="w-full leading-9"
                      >
              <Text as="p" className="!font-normal !text-gray-900_b2">
                Read more
              </Text>
            </Link>
          </div>
          
          
          
          <div className="flex flex-col items-center gap-[38px]">
            <Heading as="h2">Our Blog</Heading>
            <div className="grid grid-cols-3 justify-center gap-8 self-stretch md:grid-cols-2 sm:grid-cols-1">
              <div className="flex w-full flex-col items-start">
                <Img
                  src="images/blog5_square.webp"
                  alt="27_jan_2024"
                  className="h-[285px] w-full object-cover md:h-auto"
                />
                <Text as="p" className="mt-[34px]">
                  July 18, 2024
                </Text>
                <Link
                      to="/readblog5"
                      style={{ color: "ffffff", textDecoration: "none" }}
                      className="w-full leading-9"
                      >
                  <Text size="lg" as="p" className="!text-gray-900_03">
                    Enhancing Efficiency and Security in Logistics and Transportation with Quickall Blockchain
                  </Text>
                </Link>
                <div className="mt-4 flex flex-col self-stretch">
                  <Text as="p" className="relative z-[1] !font-normal leading-7">
                  The efficiency and security of logistics and transportation systems controlled centrally are ..
                  </Text>
                  <div className="relative mt-[-1px] flex items-center gap-3">
                  <Link
                      to="/readblog5"
                      style={{ color: "ffffff", textDecoration: "none" }}
                      className="w-full leading-9"
                      >
                      <Text as="p" className="!text-gray-900_03">
                        Read More
                      </Text>
                    </Link>
                    
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col gap-[34px]">
                <Img src="images/blog4_square.webp" alt="imageeleven" className="h-[285px] object-cover" />
                <div className="flex flex-col items-start gap-4">
                  <Text as="p">July 11, 2024 </Text>
                  <Link
                      to="/readblog4"
                      style={{ color: "ffffff", textDecoration: "none" }}
                      className="w-full leading-9"
                      >
                    <Text size="lg" as="p" className="!text-gray-900_03">
                    How Quickall's Blockchain Will Enhance Overseas Remittance and Help in Banking Operations
                    </Text>
                  </Link>
                  <div className="flex flex-col self-stretch">
                    <Text as="p" className="!font-normal leading-7">
                    Efficiency and security are of the utmost importance in the financial sector, particularly in the realm of banking 
                    </Text>
                    <div className="relative mt-[-1px] flex items-center gap-3">
                    <Link
                      to="/readblog4"
                      style={{ color: "ffffff", textDecoration: "none" }}
                      className="w-full leading-9"
                      >
                        <Text as="p" className="!text-gray-900_03">
                          Read More
                        </Text>
                      </Link>
                      <Img src="images/img_arrow_2.svg" alt="arrowone" className="h-px" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start">
                <Img
                  src="images/blog3_banner.webp"
                  alt="httpswwwpex"
                  className="h-[285px] w-full object-cover md:h-auto"
                />
                <Text as="p" className="mt-[29px]">
                  June 7, 2024
                </Text>
                <Link
                      to="/readblog3"
                      style={{ color: "ffffff", textDecoration: "none" }}
                      className="w-full leading-9"
                      >
                  <Text size="lg" as="p" className="!text-gray-900_03">
                    <span className="text-gray-900_03">
                    QuickAll: Building a Decentralized Blockchain Compliant with SEC Regulations
                    </span>
                    <span className="text-gray-900_03"></span>
                  </Text>
                </Link>
                <div className="mt-4 flex flex-col self-stretch">
                  <Text as="p" className="!font-normal leading-6">
                  The regulatory compliance and centralization of the cryptocurrency sector are the subject of intense scrutiny. 
                  </Text>
                  <div className="relative mt-[-1px] flex items-center gap-3">
                  <Link
                      to="/readblog3"
                      style={{ color: "ffffff", textDecoration: "none" }}
                      className="w-full leading-9"
                      >
                      <Text as="p" className="!text-gray-900_03">
                        Read More
                      </Text>
                    </Link>
                    <Img src="images/img_arrow_2.svg" alt="arrowone" className="h-px" />
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start">
                <Img src="images/blog2_square.webp" alt="imagetwelve" className="h-[285px] w-full object-cover md:h-auto" />
                <Text as="p" className="mt-[34px]">
                  May 15, 2024
                </Text>
                <Link
                      to="/readblog2"
                      style={{ color: "ffffff", textDecoration: "none" }}
                      className="w-full leading-9"
                      >
                  <Text size="lg" as="p" className="!text-gray-900_03">
                  Decentralized AI: Quickall's Approach to Blockchain Governance with Decentralized Artificial Fragmentary Intelligence (D-AFI)
                  </Text>
                </Link>
                <div className="mt-4 flex flex-col self-stretch">
                  <Text as="p" className="!font-normal leading-7">
                  G Chin, the founder of Quickall blockchain, was the first in the world to begin developing the concept..
                  </Text>
                  <div className="relative mt-[-1px] flex items-center gap-3">
                  <Link
                      to="/readblog2"
                      style={{ color: "ffffff", textDecoration: "none" }}
                      className="w-full leading-9"
                      >
                      <Text as="p" className="!text-gray-900_03">
                        Read More
                      </Text>
                    </Link>
                    <Img src="images/img_arrow_2.svg" alt="arrowone" className="mb-2.5 h-px self-end" />
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start">
                <Img
                  src="images/blog1_square.webp"
                  alt="imagethirteen"
                  className="h-[285px] w-full object-cover md:h-auto"
                />
                <Text as="p" className="mt-[34px]">
                  May 02, 2024{" "}
                </Text>
                <Link
                      to="/readblog1"
                      style={{ color: "ffffff", textDecoration: "none" }}
                      className="w-full leading-9"
                      >
                  <Text size="lg" as="p" className="!text-gray-900_03">
                  The Future of Blockchain Technology and Artificial Intelligence
                  </Text>
                </Link>
                <div className="mt-4 flex flex-col self-stretch">
                  <Text as="p" className="!font-normal leading-7">
                  G Chin, the founder of Quickall blockchain, was one of the first in the world that started to develop a concept
                  
                  </Text>
                  <div className="relative mt-[-1px] flex items-center gap-3">
                  <Link
                      to="/readblog1"
                      style={{ color: "ffffff", textDecoration: "none" }}
                      className="w-full leading-9"
                      >
                      <Text as="p" className="!text-gray-900_03">
                        Read More
                      </Text>
                    </Link>
                    <Img src="images/img_arrow_2.svg" alt="arrowone" className="mb-2.5 h-px self-end" />
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start">
                <Img
                  src="images/img_group_23.png"
                  alt="imagefourteen"
                  className="h-[285px] w-full object-cover md:h-auto"
                />
                <Text as="p" className="mt-[34px]">
                  April 14, 2024
                </Text>
                <Link
                      to="/readblog"
                      style={{ color: "ffffff", textDecoration: "none" }}
                      className="w-full leading-9"
                      >
                  <Text size="lg" as="p" className="!text-gray-900_03">
                  Overcoming Centralization Challenges in Blockchain: How Quickall's AI-Driven Approach Enhances Decentralization
                  </Text>
                </Link>
                <div className="mt-4 flex flex-col self-stretch">
                  <Text as="p" className="!font-normal leading-7">
                  Numerous sectors have been revolutionized by blockchain technology, which has the potential to 
                  </Text>
                  <div className="relative mt-[-1px] flex items-center gap-3">
                  <Link
                      to="/readblog"
                      style={{ color: "ffffff", textDecoration: "none" }}
                      className="w-full leading-9"
                      >
                      <Text as="p" className="!text-gray-900_03">
                        Read More
                      </Text>
                    </Link>
                    <Img src="images/img_arrow_2.svg" alt="arrowone" className="mb-2.5 h-px self-end" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer className="self-stretch" />
      </div>
    </>
  );
}
